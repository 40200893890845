import React, { useRef } from "react";
import { useDrag, useDrop, XYCoord } from "react-dnd";
import Box from "@mui/material/Box";
import { useAppDispatch } from "../../../Redux/hooks";
import { DroppableItem, supportedTypes } from "../questionTypes";
import {
  addSubquestion,
  handleQuestionDrop,
  handleQuestionMove,
} from "../../../Redux/reducers/questionSlice";
import { colFlexStyle } from "../../Common/styles/flex";
import { Button, SxProps } from "@mui/material";
import Settings from "./Settings";
import QuestionItem from "./QuestionItem";
import { Add } from "@mui/icons-material";

type Props = {
  question: any;
  editable: boolean;
  index: number;
};

export const QuestionItemStyle: SxProps = {
  minHeight: "170px",
  width: "100%",
  p: 2.5,
  border: 1,
  borderColor: "#E7E7E7",
  borderRadius: 1,
  ...colFlexStyle,
  alignItems: "center",
  justifyContent: "space-between",
  "&:not(:last-child)": {
    marginBottom: 2.5,
  },
};

const QuestionItemWrapper: React.FC<Props> = ({
  question,
  editable,
  index,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();

  const [, drop] = useDrop(
    () => ({
      accept: supportedTypes,
      drop: (dropItem: DroppableItem) => {
        //Prevents already existing items to be dropped. Handled in hover handler.
        if (dropItem?.index === undefined) {
          dispatch(handleQuestionDrop({ type: dropItem.type, index }));
        }
      },
      hover(item: DroppableItem, monitor) {
        if (!ref.current) {
          return;
        }
        if (item.index === undefined) {
          return;
        }
        const dragIndex = item.index;
        const dropIndex = index;

        if (dragIndex === dropIndex) {
          return;
        }

        const hoverBoundingRect = ref.current?.getBoundingClientRect();

        const hoverMiddleY =
          (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

        const clientOffset = monitor.getClientOffset();

        const hoverClientY =
          (clientOffset as XYCoord).y - hoverBoundingRect.top;
        if (dragIndex < dropIndex && hoverClientY < hoverMiddleY) {
          return;
        }
        if (dragIndex > dropIndex && hoverClientY > hoverMiddleY) {
          return;
        }
        dispatch(handleQuestionMove({ dragIndex, dropIndex }));
        item.index = dropIndex;
      },
      canDrop: () => editable,
    }),
    [editable, index]
  );

  const [{ opacity }, drag] = useDrag(() => {
    return {
      type: question.type,
      item: {
        type: question.type,
        index: index,
      },
      collect: (monitor) => ({
        opacity: monitor.isDragging() ? 0.5 : 1,
      }),
    };
  }, [index, question.type]);

  drag(drop(ref));

  return (
    <Box ref={ref} sx={QuestionItemStyle} style={{ opacity }}>
      <QuestionItem
        key={"question-item" + question.id}
        index={index}
        question={question}
        editable={editable}
      />
      {question.questions &&
        question.questions.map((subQuestion: any, subIndex: number) => {
          return (
            <Box
              sx={QuestionItemStyle}
              key={"question-item-wrapper" + subQuestion.id}
            >
              <QuestionItem
                key={"question-item" + subQuestion.id}
                index={subIndex}
                question={subQuestion}
                editable={editable}
                parentIndex={index}
              />
              <Settings
                index={subIndex}
                key={"settings" + subQuestion.id}
                parentIndex={index}
                editable={editable}
              />
            </Box>
          );
        })}
      {question.questions && editable && (
        <Button
          aria-label="add"
          size="medium"
          onClick={() =>
            dispatch(
              addSubquestion({
                questionIndex: index,
              })
            )
          }
          sx={{ alignSelf: "flex-end", mb: 2 }}
          startIcon={<Add fontSize="medium" sx={{ color: "primary.main" }} />}
        >
          Add Subquestion
        </Button>
      )}
      <Settings
        index={index}
        key={"settings" + question.id}
        editable={editable}
      />
    </Box>
  );
};

export default React.memo(QuestionItemWrapper);
