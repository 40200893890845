import {
  LinearProgress,
  Table,
  TableBody,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  ListItemText,
  TextField,
  ListItemIcon,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";

import http from "../../utils/http";
import { errorToastMessage } from "../../utils/toast";
import {
  NoDataContainer,
  pageSize,
  paginationLabel,
  StyledTableCell,
  TablePaginationStyle,
} from "../Common/styles/table";
import { HeaderLeftContent, StyledHeader } from "../Common/styles/header";
import { HeaderRightContent } from "./../Common/styles/header";
import { FilterAlt, MoreVert } from "@mui/icons-material";
import CreateActivityModal from "./CreateActivityModal";
import { debounce } from "lodash";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";

const Activities = () => {
  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState<Boolean>(true);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [toggleLoader, setToggleLoader] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [row, setRow] = useState<any>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [filterEl, setFilterEl] = useState<null | HTMLElement>(null);
  const openFilter = Boolean(filterEl);
  const [searchText, setSearch] = useState<string>("");
  const [filterValue, setFilterValue] = useState("");
  const [selectedFilter, setSelectedFilter] = useState<string>(filterValue);

  const modifySearchTerm = useMemo(
    () =>
      debounce((val) => {
        setPage(0);
        setSearch(val);
      }, 500),
    []
  );

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        setLoading(true);
        let url = `/daily-task?size=${pageSize}&page=${page + 1}`;
        if (searchText) {
          url += `&search=${searchText}`;
        }
        if (filterValue) {
          url += `&isVisible=${filterValue}`;
        }
        const res = await http.get(url);
        setTotal(res.data?.data?.count);
        const requests = res.data?.data?.rows?.map((request: any) => {
          return {
            id: request?.id,
            title: request?.title,
            titleZh: request?.titleZh,
            titleTa: request?.titleTa,
            titleMs: request?.titleMs,
            isVisible: request?.isVisible,
          };
        });
        setData(requests);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        errorToastMessage(err as Error);
      }
    };
    fetchDetails();
  }, [setPage, setData, page, toggleLoader, searchText, filterValue]);

  const refreshPage = () => {
    setToggleLoader((prev) => !prev);
  };
  const handleChangePage = (_1: any, newPage: number) => {
    setPage(newPage);
  };
  const createActivity = () => {
    setShowModal(true);
  };
  const closeModal = () => {
    setShowModal(false);
    setRow(null);
  };
  const handleMenuClick = (event: React.MouseEvent<HTMLElement>, row: any) => {
    setRow(row);
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
    setRow(null);
  };

  const openActivityFilterMenu = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setFilterEl(event.currentTarget);
  };

  const closeActivityFilterMenu = () => {
    setFilterEl(null);
    if (filterValue !== selectedFilter) {
      setFilterValue(selectedFilter);
      setPage(0);
    }
  };

  const handleActivityFilter = (value: string) => {
    setSelectedFilter(value);
  };

  const clearFilter = () => {
    setSelectedFilter("");
    setPage(0);
  };

  return (
    <>
      <StyledHeader>
        <Box sx={HeaderLeftContent}>
          <Typography fontSize={30} fontWeight="bold">
            Daily Activities
          </Typography>
          <TextField
            placeholder="Search for Activities"
            style={{ width: "300px" }}
            defaultValue={searchText}
            onChange={(e) => modifySearchTerm(e.target.value)}
          />
        </Box>
        <Box sx={HeaderRightContent}>
          <Button variant="contained" onClick={createActivity}>
            Create Activity
          </Button>
        </Box>
      </StyledHeader>
      <Box sx={{ p: 2.5, height: "calc(100% - 85px)", overflow: "auto" }}>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell>Title</StyledTableCell>
              <StyledTableCell>Title (in Chinese)</StyledTableCell>
              <StyledTableCell>Title (in Malay)</StyledTableCell>
              <StyledTableCell>Title (in Tamil)</StyledTableCell>
              <StyledTableCell>Visibility</StyledTableCell>
              <StyledTableCell align="right">
                <IconButton onClick={openActivityFilterMenu}>
                  <FilterAlt color={filterValue ? "primary" : "disabled"} />
                </IconButton>
              </StyledTableCell>
            </TableRow>
          </TableHead>
          {!loading && data.length > 0 && (
            <>
              <TableBody>
                {data?.map((row: any) => (
                  <TableRow key={row?.id}>
                    <StyledTableCell>{row?.title}</StyledTableCell>
                    <StyledTableCell>{row?.titleZh}</StyledTableCell>
                    <StyledTableCell>{row?.titleMs}</StyledTableCell>
                    <StyledTableCell>{row?.titleTa}</StyledTableCell>
                    <StyledTableCell>
                      {row?.isVisible ? "Visible" : "Invisible"}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      <IconButton onClick={(e) => handleMenuClick(e, row)}>
                        <MoreVert />
                      </IconButton>
                    </StyledTableCell>
                  </TableRow>
                ))}
              </TableBody>
              {total > pageSize && (
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      sx={TablePaginationStyle}
                      count={total}
                      page={page}
                      rowsPerPage={pageSize}
                      rowsPerPageOptions={[pageSize]}
                      onPageChange={handleChangePage}
                      labelDisplayedRows={paginationLabel}
                    />
                  </TableRow>
                </TableFooter>
              )}
            </>
          )}
        </Table>
        {!loading && data.length === 0 && (
          <NoDataContainer>
            <Typography variant="body1" color="gray">
              No Data
            </Typography>
          </NoDataContainer>
        )}
        {loading && (
          <Box sx={{ width: "100%" }}>
            <LinearProgress />
          </Box>
        )}
        <Menu anchorEl={anchorEl} open={open} onClose={handleMenuClose}>
          <MenuItem
            onClick={() => {
              createActivity();
              setAnchorEl(null);
            }}
          >
            Edit
          </MenuItem>
          {/* <MenuItem>
            <ListItemText>Delete</ListItemText>
          </MenuItem> */}
        </Menu>
      </Box>
      {showModal && (
        <CreateActivityModal
          showModal={showModal}
          closeModal={closeModal}
          activity={row}
          refreshPage={refreshPage}
        />
      )}
      <Menu
        anchorEl={filterEl}
        open={openFilter}
        onClose={closeActivityFilterMenu}
        sx={{
          "& .MuiPaper-root": {
            width: 200,
          },
        }}
      >
        {["true", "false"].map((item: string) => (
          <MenuItem
            selected={item === selectedFilter}
            key={item}
            onClick={() => handleActivityFilter(item)}
          >
            <ListItemIcon>
              {item === selectedFilter ? (
                <RadioButtonCheckedIcon fontSize="small" color="primary" />
              ) : (
                <RadioButtonUncheckedIcon fontSize="small" />
              )}
            </ListItemIcon>
            <ListItemText sx={{ textTransform: "capitalize" }}>
              {item === "true" ? "Visible" : "Invisible"}
            </ListItemText>
          </MenuItem>
        ))}
        <Box
          sx={{
            display: "flex",
            gap: 1,
            justifyContent: "center",
            position: "sticky",
            bottom: 0,
            bgcolor: "#fff",
            p: 1,
          }}
        >
          <Button variant="text" onClick={clearFilter}>
            Reset
          </Button>
          <Button variant="contained" onClick={closeActivityFilterMenu}>
            OK
          </Button>
        </Box>
      </Menu>
    </>
  );
};

export default Activities;
