import React from "react";
import { useDrop } from "react-dnd";
import { DroppableItem, supportedTypes } from "../questionTypes";
import { useAppDispatch, useAppSelector } from "../../../Redux/hooks";
import { Box, SxProps } from "@mui/material";
import { handleQuestionDrop } from "../../../Redux/reducers/questionSlice";
import QuestionItemWrapper from "./QuestionItemWrapper";

export const QuestionListStyle: SxProps = {
  flex: "1",
  minWidth: "0px",
  height: "100%",
  position: "relative",
  px: 3.75,
  py: 2.5,
  overflow: "auto",
};

const QuestionItemContainer: React.FC = () => {
  const { questions, editable } = useAppSelector((state) => state.question);
  return (
    <>
      {questions.map((question, index) => {
        return (
          <QuestionItemWrapper
            key={question.id}
            editable={editable}
            index={index}
            question={question}
          />
        );
      })}
    </>
  );
};

const DropArea: React.FC = () => {
  const dispatch = useAppDispatch();
  const editable = useAppSelector((state) => state.question.editable);

  const [{ canDrop }, drop] = useDrop(
    () => ({
      accept: supportedTypes,
      drop: (dropItem: DroppableItem, monitor) => {
        //Did drop is true if a child drag source has handled the drop
        if (!monitor.didDrop() && dropItem?.index === undefined)
          dispatch(handleQuestionDrop({ type: dropItem.type }));
      },
      canDrop: () => editable,
      collect: (monitor) => {
        return {
          canDrop: monitor.canDrop(),
        };
      },
    }),
    [editable]
  );

  return (
    <Box
      ref={drop}
      sx={QuestionListStyle}
      style={{
        border: canDrop ? "2px dashed #dbdbdb" : "none",
      }}
    >
      <QuestionItemContainer />
    </Box>
  );
};

export default DropArea;
