import { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  LinearProgress,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  TableFooter,
  TablePagination,
  Typography,
} from "@mui/material";
import {
  Archive,
  ContentCopy,
  Delete,
  Edit,
  FilterAlt,
  IosShare,
  MoreVert,
  Restore,
} from "@mui/icons-material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { AxiosResponse } from "axios";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import PhotoSizeSelectActualOutlinedIcon from "@mui/icons-material/PhotoSizeSelectActualOutlined";

import { errorToastMessage, toastMessage } from "../../utils/toast";
import http from "../../utils/http";
import {
  NoDataContainer,
  pageSize,
  paginationLabel,
  StyledTableCell,
  TablePaginationStyle,
} from "../Common/styles/table";
import { useAppDispatch, useAppSelector } from "../../Redux/hooks";
import {
  setQuestionListFilterId,
  setQuestionListPage,
} from "../../Redux/reducers/questionListSlice";
import { useNavigate } from "react-router-dom";
import CreateQuestionModal from "./CreateQuestionModal";
import { fetchQuestionList } from "../../Redux/actions/questionListAction";

export const downloadFile = async (myData: any) => {
  const fileName = myData.name || myData.title;
  const json = JSON.stringify(myData);
  const blob = new Blob([json], { type: "application/json" });
  const href = await URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = href;
  link.download = fileName + ".json";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const QuestionList = () => {
  const dispatch = useAppDispatch();
  const { loading, page, filterId, searchText, total, quesData } =
    useAppSelector((state) => state.quesList);
  const [selectedOption, setSelectedOption] = useState(filterId);
  const [menuLoader, setMenuLoader] = useState("");
  const [toggleLoader, setToggleLoader] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>({});
  const [showEditModal, setShowEditModal] = useState(false);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [filterEl, setFilterEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const openFilter = Boolean(filterEl);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchQuestionList(page, filterId, searchText));
  }, [page, filterId, searchText, toggleLoader, dispatch]);

  const handleChangePage = (_1: any, newPage: number) => {
    dispatch(setQuestionListPage(newPage));
  };

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const openFilterMenu = (event: React.MouseEvent<HTMLElement>) => {
    setFilterEl(event?.currentTarget);
  };

  const handleFilterItem = (value: string) => {
    setSelectedOption(value);
  };
  const closeFilterMenu = () => {
    setFilterEl(null);
    if (selectedOption !== filterId) {
      dispatch(setQuestionListFilterId(selectedOption));
    }
  };

  const openEditModal = () => {
    setShowEditModal(true);
    handleMenuClose();
  };

  const closeEditModal = () => {
    setShowEditModal(false);
  };

  const duplicateQuestionnaire = async (id: string, action: string) => {
    try {
      setMenuLoader(action);
      const res: AxiosResponse = await http.post(
        `/questionnaires/${id}/duplicate`
      );
      setMenuLoader("");
      toastMessage("success", res.data?.message);
      setAnchorEl(null);
      setToggleLoader((prev) => !prev);
    } catch (err) {
      setMenuLoader("");
      errorToastMessage(err as Error);
    }
  };
  const exportQuestionnaire = async (id: string, action: string) => {
    try {
      setMenuLoader(action);
      const res = await http.get(`/questionnaires/${id}/questions`);
      await downloadFile(res.data?.data);
      setMenuLoader("");
    } catch (err) {
      setMenuLoader("");
      errorToastMessage(err as Error);
    }
  };
  const activateArchiveQuestionnaire = async (record: any, action: string) => {
    try {
      setMenuLoader(action);
      let body = {
        status: record?.status === "archived" ? "active" : "archived",
      };
      const res: AxiosResponse = await http.put(
        `/questionnaires/${record?.id}`,
        body
      );
      setMenuLoader("");
      toastMessage("success", res.data?.message);
      setAnchorEl(null);
      setToggleLoader((prev) => !prev);
    } catch (err) {
      setMenuLoader("");
      errorToastMessage(err as Error);
    }
  };
  const deleteQuestionnaire = async (id: string, action: string) => {
    try {
      setMenuLoader(action);
      const res: AxiosResponse = await http.delete(`/questionnaires/${id}`);
      setMenuLoader("");
      toastMessage("success", res.data?.message);
      setAnchorEl(null);
      setToggleLoader((prev) => !prev);
    } catch (err) {
      setMenuLoader("");
      errorToastMessage(err as Error);
    }
  };

  const refresh = () => {
    setToggleLoader((prev) => !prev);
  };

  return (
    <>
      <Box>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <StyledTableCell>Name</StyledTableCell>
              <StyledTableCell>Status</StyledTableCell>
              <StyledTableCell align="right">
                <IconButton onClick={openFilterMenu}>
                  <FilterAlt color={filterId ? "primary" : "disabled"} />
                </IconButton>
              </StyledTableCell>
            </TableRow>
          </TableHead>
          {!loading && quesData.length > 0 && (
            <>
              <TableBody>
                {quesData?.map((row: any) => (
                  <TableRow key={row?.id}>
                    <StyledTableCell
                      onClick={() =>
                        navigate(
                          row?.status === "active"
                            ? `/app/question/preview?id=${row?.id}`
                            : `/app/question/builder?id=${row?.id}`
                        )
                      }
                      sx={{
                        display: "flex",
                        gap: 2,
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                    >
                      <Avatar
                        src={row?.imageUrl}
                        sx={{
                          width: 50,
                          height: 50,
                          fontSize: 14,
                          bgcolor: "lightgray",
                          color: "#000",
                        }}
                      >
                        <PhotoSizeSelectActualOutlinedIcon />
                      </Avatar>
                      <Box>{row?.name}</Box>
                    </StyledTableCell>
                    <StyledTableCell sx={{ textTransform: "capitalize" }}>
                      {row?.status}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      <IconButton
                        onClick={(e) => {
                          handleMenuClick(e);
                          setSelectedRow(row);
                        }}
                      >
                        <MoreVert />
                      </IconButton>
                    </StyledTableCell>
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  {total > pageSize && (
                    <TablePagination
                      sx={TablePaginationStyle}
                      count={total}
                      page={page}
                      rowsPerPage={pageSize}
                      rowsPerPageOptions={[pageSize]}
                      onPageChange={handleChangePage}
                      labelDisplayedRows={paginationLabel}
                    />
                  )}
                </TableRow>
              </TableFooter>
            </>
          )}
        </Table>
        {!loading && quesData.length === 0 && (
          <NoDataContainer>
            <Typography variant="body1" color="gray">
              No Data
            </Typography>
          </NoDataContainer>
        )}
        {loading && (
          <Box sx={{ width: "100%" }}>
            <LinearProgress />
          </Box>
        )}
      </Box>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
        PaperProps={{
          style: {
            minWidth: "225px",
          },
        }}
      >
        <MenuItem disabled={menuLoader ? true : false} onClick={openEditModal}>
          <ListItemIcon>
            <Edit fontSize="small" />
          </ListItemIcon>
          <ListItemText>Edit</ListItemText>
        </MenuItem>
        <MenuItem
          disabled={menuLoader ? true : false}
          onClick={() => duplicateQuestionnaire(selectedRow?.id, "duplicate")}
        >
          <ListItemIcon>
            <ContentCopy fontSize="small" />
          </ListItemIcon>
          <ListItemText>Duplicate</ListItemText>
          {menuLoader === "duplicate" && (
            <ListItemIcon>
              <CircularProgress size={18} sx={{ ml: 1 }} />
            </ListItemIcon>
          )}
        </MenuItem>
        <MenuItem
          disabled={menuLoader ? true : false}
          onClick={() => exportQuestionnaire(selectedRow?.id, "export")}
        >
          <ListItemIcon>
            <IosShare fontSize="small" />
          </ListItemIcon>
          <ListItemText>Export</ListItemText>
          {menuLoader === "export" && (
            <ListItemIcon>
              <CircularProgress size={18} sx={{ ml: 1 }} />
            </ListItemIcon>
          )}
        </MenuItem>
        {selectedRow?.status !== "draft" && (
          <MenuItem
            disabled={menuLoader ? true : false}
            onClick={() => activateArchiveQuestionnaire(selectedRow, "status")}
          >
            <ListItemIcon>
              {selectedRow?.status === "archived" ? (
                <Restore fontSize="small" />
              ) : (
                <Archive fontSize="small" />
              )}
            </ListItemIcon>
            <ListItemText>
              {selectedRow?.status === "archived" ? "Restore" : "Archive"}
            </ListItemText>
            {menuLoader === "status" && (
              <ListItemIcon>
                <CircularProgress size={18} sx={{ ml: 1 }} />
              </ListItemIcon>
            )}
          </MenuItem>
        )}
        <Divider />
        <MenuItem
          disabled={menuLoader ? true : false}
          onClick={() => deleteQuestionnaire(selectedRow?.id, "delete")}
        >
          <ListItemIcon>
            <Delete fontSize="small" color="error" />
          </ListItemIcon>
          <ListItemText>Delete</ListItemText>
          {menuLoader === "delete" && (
            <ListItemIcon>
              <CircularProgress size={18} sx={{ ml: 1 }} />
            </ListItemIcon>
          )}
        </MenuItem>
      </Menu>

      <Menu
        anchorEl={filterEl}
        open={openFilter}
        onClose={closeFilterMenu}
        PaperProps={{
          style: {
            maxHeight: "400px",
            width: "20ch",
          },
        }}
      >
        {["active", "draft", "archived"]?.map((item: any) => (
          <MenuItem
            selected={item === selectedOption}
            key={item}
            onClick={() => handleFilterItem(item)}
          >
            <ListItemIcon>
              {item === selectedOption ? (
                <RadioButtonCheckedIcon fontSize="small" color="primary" />
              ) : (
                <RadioButtonUncheckedIcon fontSize="small" />
              )}
            </ListItemIcon>
            <ListItemText sx={{ textTransform: "capitalize" }}>
              {item === "archived" ? "archive" : item}
            </ListItemText>
          </MenuItem>
        ))}
        <Box
          sx={{
            display: "flex",
            gap: 1,
            justifyContent: "center",
            position: "sticky",
            bottom: 0,
            bgcolor: "#fff",
            p: 1,
          }}
        >
          <Button variant="text" onClick={() => handleFilterItem("")}>
            Reset
          </Button>
          <Button variant="contained" onClick={closeFilterMenu}>
            OK
          </Button>
        </Box>
      </Menu>
      {showEditModal && (
        <CreateQuestionModal
          showModal={showEditModal}
          closeModal={closeEditModal}
          questionnaire={selectedRow}
          refreshPage={refresh}
        />
      )}
    </>
  );
};

export default QuestionList;
