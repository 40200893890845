import {
  Box,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { useAppDispatch } from "../../../../Redux/hooks";
import { changeQuestionProperty } from "../../../../Redux/reducers/onboardingSlice";
import { OnboardingQuestion } from "../../../../types/onboarding";
import UploadItem from "./UploadItem";

type Props = {
  question: OnboardingQuestion;
  subIndex?: number;
};

const BasicDetails: React.FC<Props> = ({ question, subIndex }) => {
  const dispatch = useAppDispatch();
  const {
    variableName,
    title,
    titleZh,
    titleMs,
    titleTa,
    exit,
    imageUrl,
    type,
    userType,
  } = question;

  const changeValue = (type: string, value: any) => {
    dispatch(changeQuestionProperty({ type, value, subIndex }));
  };

  return (
    <>
      <Box mb={2}>
        <Typography variant="subtitle1" mb={2} fontWeight="medium">
          Title
        </Typography>
        <TextField
          fullWidth
          value={title}
          placeholder="Title"
          onChange={(e) => changeValue("title", e.target.value)}
        />
      </Box>
      <Box mb={2}>
        <Typography variant="subtitle1" mb={2} fontWeight="medium">
          Title (Chinese)
        </Typography>
        <TextField
          fullWidth
          value={titleZh}
          placeholder="Title"
          onChange={(e) => changeValue("titleZh", e.target.value)}
        />
      </Box>
      <Box mb={2}>
        <Typography variant="subtitle1" mb={2} fontWeight="medium">
          Title (Malay)
        </Typography>
        <TextField
          fullWidth
          value={titleMs}
          placeholder="Title"
          onChange={(e) => changeValue("titleMs", e.target.value)}
        />
      </Box>
      <Box mb={2}>
        <Typography variant="subtitle1" mb={2} fontWeight="medium">
          Title (Tamil)
        </Typography>
        <TextField
          fullWidth
          value={titleTa}
          placeholder="Title"
          onChange={(e) => changeValue("titleTa", e.target.value)}
        />
      </Box>
      <Box mb={2}>
        <Typography variant="subtitle1" mb={2} fontWeight="medium">
          Variable Name
        </Typography>
        <TextField
          fullWidth
          value={variableName}
          placeholder="Variable Name"
          onChange={(e) => changeValue("variableName", e.target.value)}
        />
      </Box>
      {subIndex === undefined && (
        <Box mb={2}>
          <Typography variant="subtitle1" mb={2} fontWeight="medium">
            User Type (for progress)
          </Typography>
          <Select
            id="userType"
            fullWidth
            value={userType}
            onChange={(e) => changeValue("userType", e.target.value)}
          >
            <MenuItem key="patient" value={1}>
              Patient
            </MenuItem>
            <MenuItem key="Caregiver" value={2}>
              Caregiver
            </MenuItem>
            <MenuItem key="Both" value={0}>
              Both Caregiver and Patient
            </MenuItem>
          </Select>
        </Box>
      )}
      {type === "statement" && (
        <UploadItem image={imageUrl} subIndex={subIndex} />
      )}
      {subIndex === undefined && (
        <Box mb={2}>
          <Typography variant="subtitle1" mb={2} fontWeight="medium">
            Exit Onboarding
          </Typography>
          <Switch
            checked={exit}
            onChange={(e) => changeValue("exit", e.target.checked)}
          />
        </Box>
      )}
    </>
  );
};

export default BasicDetails;
