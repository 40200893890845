import React, { useState } from "react";
import { Form, Formik } from "formik";
import * as yup from "yup";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
} from "@mui/material";
import { ModalBaseStyles, ModalHeader } from "../Common/styles/modal";

import http from "../../utils/http";
import { errorToastMessage, toastMessage } from "../../utils/toast";
import { InputWrapper, LabelStyle } from "../Common/styles/form";

const schema = yup.object().shape({
  title: yup.string().required("Activity Title is Required"),
  titleZh: yup.string().required("Activity Title in Chinese is Required"),
  titleTa: yup.string().required("Activity Title in Mandarin is Required"),
  titleMs: yup.string().required("Activity Title in Malay is Required"),
});

const CreateActivityModal: React.FC<any> = ({
  showModal,
  closeModal,
  activity,
  refreshPage,
}: any) => {
  const [buttonLoader, setButtonLoader] = useState(false);

  const submitHandler = async (values: any) => {
    try {
      const body = {
        title: values.title,
        titleZh: values.titleZh,
        titleTa: values.titleTa,
        titleMs: values.titleMs,
        isVisible: values.isVisible === "true" ? true : false,
      };
      setButtonLoader(true);
      let res: any;
      if (activity) {
        res = await http.patch(`/daily-task/${activity.id}`, body);
      } else {
        res = await http.post(`/daily-task`, body);
      }
      toastMessage("success", res.data.message);
      closeModal();
      refreshPage();
    } catch (err) {
      setButtonLoader(false);
      errorToastMessage(err as Error);
    }
  };
  return (
    <Modal open={showModal} onClose={closeModal}>
      <Box sx={ModalBaseStyles}>
        <ModalHeader
          title={activity?.id ? "Edit Daily Activity" : `Create Daily Activity`}
          onCloseClick={closeModal}
        />
        <Formik
          initialValues={{
            title: activity?.title || "",
            titleZh: activity?.titleZh || "",
            titleTa: activity?.titleTa || "",
            titleMs: activity?.titleMs || "",
            isVisible: activity
              ? activity?.isVisible
                ? "true"
                : "false"
              : "true",
          }}
          validationSchema={schema}
          onSubmit={(values) => {
            submitHandler(values);
          }}
        >
          {({ errors, touched, getFieldProps }: any) => (
            <Form>
              <FormControl sx={InputWrapper}>
                <FormLabel sx={LabelStyle} htmlFor="title">
                  Title
                </FormLabel>
                <TextField
                  id="title"
                  placeholder="Title"
                  error={touched?.title && errors?.title ? true : false}
                  helperText={
                    touched?.title && errors?.title ? errors?.title : " "
                  }
                  {...getFieldProps("title")}
                />
              </FormControl>
              <FormControl sx={InputWrapper}>
                <FormLabel sx={LabelStyle} htmlFor="titleZh">
                  Title (in Chinese)
                </FormLabel>
                <TextField
                  id="titleZh"
                  placeholder="Title (in Chinese)"
                  error={touched?.titleZh && errors?.titleZh ? true : false}
                  helperText={
                    touched?.titleZh && errors?.titleZh ? errors?.titleZh : " "
                  }
                  {...getFieldProps("titleZh")}
                />
              </FormControl>
              <FormControl sx={InputWrapper}>
                <FormLabel sx={LabelStyle} htmlFor="titleTa">
                  Title (in Tamil)
                </FormLabel>
                <TextField
                  id="titleTa"
                  placeholder="Title (in Tamil)"
                  error={touched?.titleTa && errors?.titleTa ? true : false}
                  helperText={
                    touched?.titleTa && errors?.titleTa ? errors?.titleTa : " "
                  }
                  {...getFieldProps("titleTa")}
                />
              </FormControl>
              <FormControl sx={InputWrapper}>
                <FormLabel sx={LabelStyle} htmlFor="titleMs">
                  Title (in Malay)
                </FormLabel>
                <TextField
                  id="titleMs"
                  placeholder="Title (in Malay)"
                  error={touched?.titleMs && errors?.titleMs ? true : false}
                  helperText={
                    touched?.titleMs && errors?.titleMs ? errors?.titleMs : " "
                  }
                  {...getFieldProps("titleMs")}
                />
              </FormControl>
              <FormControl sx={InputWrapper}>
                <FormLabel sx={LabelStyle} htmlFor="isVisible">
                  Visibility
                </FormLabel>
                <Select id="isVisible" {...getFieldProps("isVisible")}>
                  <MenuItem value="true">Visible</MenuItem>
                  <MenuItem value="false">Invisible</MenuItem>
                </Select>
              </FormControl>

              <Box
                sx={{
                  mt: 2,
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: 1,
                }}
              >
                {!buttonLoader ? (
                  <>
                    <Button variant="contained" type="submit">
                      Save
                    </Button>
                    <Button variant="outlined" onClick={closeModal}>
                      Cancel
                    </Button>
                  </>
                ) : (
                  <CircularProgress size={25} />
                )}
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};

export default CreateActivityModal;
