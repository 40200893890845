import { useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  LinearProgress,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { useSearchParams } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {
  IconButton,
  Menu,
  MenuItem,
  TableFooter,
  TablePagination,
} from "@mui/material";
import { AxiosResponse } from "axios";
import { MoreVert } from "@mui/icons-material";
import { DateTime } from "luxon";

import http from "../../utils/http";
import { errorToastMessage, toastMessage } from "../../utils/toast";
import {
  HeaderLeftContent,
  HeaderRightContent,
  StyledHeader,
} from "../Common/styles/header";
import {
  NoDataContainer,
  pageSize,
  paginationLabel,
  StyledTableCell,
  TablePaginationStyle,
} from "../Common/styles/table";
import CreateNotificationModal from "./CreateNotificationModal";
import { langMap } from "../../utils/lang";
import {
  ethnicityMap,
  genderMap,
  notificationEntitiesMap,
} from "../../utils/notification";

const userTarget = ["Patient and Caregiver", "Patient", "Caregiver"];

const NotificationCenter = () => {
  const [loading, setLoading] = useState<Boolean>(true);
  const [menuLoader, setMenuLoader] = useState(false);
  const [data, setData] = useState<any[]>([]);
  const [total, setTotal] = useState(0);
  const [toggleLoader, setToggleLoader] = useState(false);
  const [showModal, setShowModal] = useState(false);
  let [searchParams, setSearchParams] = useSearchParams();
  const [page, setPage] = useState(parseInt(searchParams.get("page") || "0"));
  const [type, setType] = useState(searchParams.get("type") || "upcoming");
  const [row, setRow] = useState<any>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    const params = new URLSearchParams();
    params.set("page", page.toString());
    if (type) {
      params.set("type", type);
    }
    setSearchParams(params, {
      replace: true,
    });
  }, [page, type, setSearchParams]);

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        setLoading(true);
        const res: AxiosResponse = await http.get(
          `/notifications/broadcast?size=${pageSize}&page=${
            page + 1
          }&type=${type}`
        );
        if (
          res.data.data.count > 0 &&
          res.data.data.rows?.length === 0 &&
          page > 0
        ) {
          setPage(0);
          return;
        }
        setTotal(res.data?.data?.count);
        const notifications = res.data?.data?.rows?.map((notification: any) => {
          return {
            id: notification?.id,
            title: notification?.title,
            body: notification?.body,
            scheduledOn: notification?.scheduledOn,
            scheduledOnTime: DateTime.fromISO(
              notification?.scheduledOn
            ).toFormat("dd'-'LL'-'yyyy hh:mm a"),
            entity: notification?.entity,
            entityLabel: notificationEntitiesMap[notification?.entity],
            educationLesson: notification?.educationLesson,
            educationLessonId: notification?.educationLessonId,
            bot: notification?.entity === "bot" ? notification?.bot : null,
            botId: notification?.entity === "bot" ? notification?.botId : null,
            faq: notification?.entity === "faq_bot" ? notification?.bot : null,
            faqId:
              notification?.entity === "faq_bot" ? notification?.botId : null,
            type: notification?.type,
            lang: notification?.lang || "en",
            langLabel: langMap[notification?.lang] || "English",
            users:
              notification?.users?.map((user: any) => {
                return {
                  id: user.userId,
                  name: user.user?.code,
                };
              }) || [],
            userLabel:
              notification?.users?.length > 0
                ? notification?.users
                    ?.map((user: any) => user.user?.code)
                    ?.join(", ")
                : "",
            gender: notification?.filters?.gender || "",
            genderLabel: genderMap[notification?.filters?.gender] || "",
            ethnicity: notification?.filters?.ethnicity || [],
            ethnicityLabel:
              notification?.filters?.ethnicity
                ?.map((eth: any) => {
                  return ethnicityMap[eth];
                })
                .join(", ") || "",
          };
        });
        console.log(notifications);
        setData(notifications);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        errorToastMessage(err as Error);
      }
    };
    fetchDetails();
  }, [setPage, setData, page, type, toggleLoader]);

  const toggleType = () => {
    setType((oldType) => (oldType === "upcoming" ? "completed" : "upcoming"));
    setPage(0);
  };

  const refreshPage = () => {
    setToggleLoader((prev) => !prev);
  };

  const createNotification = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setRow(null);
  };

  const handleChangePage = (_1: any, newPage: number) => {
    setPage(newPage);
  };

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>, row: any) => {
    setRow(row);
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setRow(null);
  };

  const deleteNotification = async (id: string) => {
    try {
      setMenuLoader(true);
      await http.delete(`/notifications/broadcast/${id}`);
      toastMessage("success", "Notification deleted Successfully");
      handleMenuClose();
      setMenuLoader(false);
      setToggleLoader((prev) => !prev);
    } catch (err) {
      setMenuLoader(false);
      errorToastMessage(err as Error);
    }
  };

  return (
    <>
      <StyledHeader>
        <Box sx={HeaderLeftContent}>
          <Typography fontSize={30} fontWeight="bold">
            {type === "upcoming"
              ? "Upcoming Notifications"
              : "Completed Notifications"}
          </Typography>
        </Box>
        <Box sx={HeaderRightContent}>
          {type === "upcoming" && (
            <Button variant="contained" onClick={createNotification}>
              Create Notification
            </Button>
          )}
          <Button variant="contained" onClick={toggleType}>
            {type === "upcoming"
              ? "Show completed notifications"
              : "Show upcoming notifications"}
          </Button>
        </Box>
      </StyledHeader>
      <Box sx={{ p: 2.5, height: "calc(100% - 85px)", overflow: "auto" }}>
        <Box>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <StyledTableCell>Title</StyledTableCell>
                <StyledTableCell>Body</StyledTableCell>
                <StyledTableCell>Target Audience</StyledTableCell>
                <StyledTableCell>Resource</StyledTableCell>
                <StyledTableCell>Scheduled On</StyledTableCell>
                <StyledTableCell>Language</StyledTableCell>
                <StyledTableCell></StyledTableCell>
              </TableRow>
            </TableHead>
            {!loading && data.length > 0 && (
              <>
                <TableBody>
                  {data?.map((row: any) => (
                    <TableRow key={row?.id}>
                      <StyledTableCell>{row?.title}</StyledTableCell>
                      <StyledTableCell>{row?.body}</StyledTableCell>
                      <StyledTableCell sx={{ maxWidth: "200px" }}>
                        {row?.userLabel
                          ? row?.userLabel
                          : "All " +
                            userTarget[row?.type] +
                            (row?.genderLabel ? " - " + row?.genderLabel : "") +
                            (row?.ethnicityLabel
                              ? " - " + row?.ethnicityLabel
                              : "")}
                      </StyledTableCell>
                      <StyledTableCell>
                        {row?.entityLabel +
                          (row?.educationLesson?.name ||
                          row?.bot?.name ||
                          row?.faq?.name
                            ? " - " +
                              (row?.educationLesson?.name ||
                                row?.bot?.name ||
                                row?.faq?.name)
                            : "")}
                      </StyledTableCell>
                      <StyledTableCell>{row?.scheduledOnTime}</StyledTableCell>
                      <StyledTableCell>{row?.langLabel}</StyledTableCell>
                      <StyledTableCell align="right">
                        <IconButton onClick={(e) => handleMenuClick(e, row)}>
                          <MoreVert />
                        </IconButton>
                      </StyledTableCell>
                    </TableRow>
                  ))}
                </TableBody>
                {total > pageSize && (
                  <TableFooter>
                    <TableRow>
                      <TablePagination
                        sx={TablePaginationStyle}
                        count={total}
                        page={page}
                        rowsPerPage={pageSize}
                        rowsPerPageOptions={[pageSize]}
                        onPageChange={handleChangePage}
                        labelDisplayedRows={paginationLabel}
                      />
                    </TableRow>
                  </TableFooter>
                )}
              </>
            )}
          </Table>
          {!loading && data.length === 0 && (
            <NoDataContainer>
              <Typography variant="body1" color="gray">
                No Data
              </Typography>
            </NoDataContainer>
          )}
          {loading && (
            <Box sx={{ width: "100%" }}>
              <LinearProgress />
            </Box>
          )}
        </Box>

        <Menu anchorEl={anchorEl} open={open} onClose={handleMenuClose}>
          {type === "upcoming" && (
            <MenuItem
              onClick={() => {
                createNotification();
                setAnchorEl(null);
              }}
            >
              Edit
            </MenuItem>
          )}
          <MenuItem
            onClick={() => deleteNotification(row?.id)}
            disabled={menuLoader}
          >
            <ListItemText>Delete</ListItemText>
            {menuLoader && (
              <ListItemIcon>
                <CircularProgress size={18} sx={{ ml: 1 }} />
              </ListItemIcon>
            )}
          </MenuItem>
        </Menu>
      </Box>
      {showModal && (
        <CreateNotificationModal
          showModal={showModal}
          closeModal={closeModal}
          notification={row}
          refreshPage={refreshPage}
        />
      )}
    </>
  );
};

export default NotificationCenter;
