import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import DropArea from "./DropArea/DropArea";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useAppDispatch, useAppSelector } from "../../Redux/hooks";
import QBHeader from "./QBHeader";
import {
  loadQuestionDetails,
  questionCleanup,
} from "../../Redux/actions/questionAction";
import { styled } from "@mui/material/styles";
import QuestionPicker from "./QuestionPicker/QuestionPicker";
import QuestionPropertiesModalWrapper from "./QuestionProperties/QuestionPropertiesModalWrapper";
import { CircularProgress, Typography } from "@mui/material";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers";

type Props = {};

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const QBContainer = styled("div")(() => ({
  height: "100%",
  display: "flex",
  flexDirection: "column",
  overflow: "hidden",
}));

const QBArea = styled("div")(() => ({
  display: "flex",
  alignItems: "start",
  flex: 1,
  minHeight: "0px",
}));

const QBLoader = styled("div")(() => ({
  height: "100%",
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const QuestionBuilder: React.FC<Props> = (props: Props) => {
  const query = useQuery();
  const qid: string = query.get("id") || "";

  const dispatch = useAppDispatch();
  const { loading, error } = useAppSelector((state) => state.question);

  // Load details on Mount
  useEffect(() => {
    dispatch(loadQuestionDetails(qid));
    // Store cleanup on component unmount
    return () => {
      dispatch(questionCleanup());
    };
  }, [dispatch, qid]);

  return (
    <QBContainer>
      <QBHeader />
      <QBArea>
        {loading ? (
          <QBLoader>
            <CircularProgress size={25} />
          </QBLoader>
        ) : error ? (
          <QBLoader>
            <Typography variant="h5" color="grey">
              {error}
            </Typography>
          </QBLoader>
        ) : (
          <>
            <QuestionPicker />
            <DropArea />
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <QuestionPropertiesModalWrapper />
            </LocalizationProvider>
          </>
        )}
      </QBArea>
    </QBContainer>
  );
};

const QuestionBuilderContext: React.FC<Props> = (props) => {
  return (
    <DndProvider backend={HTML5Backend}>
      <QuestionBuilder {...props} />
    </DndProvider>
  );
};

export default QuestionBuilderContext;
