import { Add, Delete } from "@mui/icons-material";
import {
  Box,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../../Redux/hooks";
import {
  addQuestionOption,
  changeQuestionOptionProperty,
  deleteQuestionOption,
} from "../../../../Redux/reducers/onboardingSlice";
import { OnboardingQuestion } from "../../../../types/onboarding";
import ReactQuill from "react-quill";
import { quillFormats, quillModules } from "../../../../utils/reactquill";

type Props = {
  question: OnboardingQuestion;
  subIndex?: number;
};

const QuestionOptions: React.FC<Props> = ({ question, subIndex }) => {
  const dispatch = useAppDispatch();
  const { questions } = useAppSelector((state) => state.onboarding);

  const { choices } = question;

  const changeOptionValue = (type: string, value: string, index: number) => {
    dispatch(
      changeQuestionOptionProperty({
        type,
        value,
        optionIndex: index,
        subIndex,
      })
    );
  };

  const addNewOption = () => {
    dispatch(addQuestionOption({ subIndex }));
  };

  const deleteOption = (index: number) => {
    dispatch(deleteQuestionOption({ optionIndex: index, subIndex }));
  };

  return (
    <>
      {choices?.map((option, index) => {
        return (
          <Box mb={2} key={option.id}>
            <Typography variant="subtitle1" mb={2} fontWeight="medium">
              {"Option " + (index + 1)}
            </Typography>
            <Box mb={2}>
              <Typography variant="subtitle1" mb={2} fontWeight="medium">
                Label
              </Typography>
              <TextField
                fullWidth
                value={option.label}
                placeholder="Label"
                onChange={(e) =>
                  changeOptionValue("label", e.target.value, index)
                }
              />
            </Box>
            <Box mb={2}>
              <Typography variant="subtitle1" mb={2} fontWeight="medium">
                Label (Chinese)
              </Typography>
              <TextField
                fullWidth
                value={option.labelZh}
                placeholder="Label"
                onChange={(e) =>
                  changeOptionValue("labelZh", e.target.value, index)
                }
              />
            </Box>
            <Box mb={2}>
              <Typography variant="subtitle1" mb={2} fontWeight="medium">
                Label (Malay)
              </Typography>
              <TextField
                fullWidth
                value={option.labelMs}
                placeholder="Label"
                onChange={(e) =>
                  changeOptionValue("labelMs", e.target.value, index)
                }
              />
            </Box>
            <Box mb={2}>
              <Typography variant="subtitle1" mb={2} fontWeight="medium">
                Label (Tamil)
              </Typography>
              <TextField
                fullWidth
                value={option.labelTa}
                placeholder="Label"
                onChange={(e) =>
                  changeOptionValue("labelTa", e.target.value, index)
                }
              />
            </Box>
            <Box sx={{ display: "flex", gap: "20px" }}>
              <Box sx={{ flex: 1, minWidth: "1px" }}>
                <Typography variant="subtitle1" mb={2} fontWeight="medium">
                  Value
                </Typography>
                <TextField
                  fullWidth
                  value={option.value}
                  placeholder="Value"
                  onChange={(e) =>
                    changeOptionValue("value", e.target.value, index)
                  }
                />
              </Box>
              {subIndex === undefined && (
                <Box sx={{ flex: 1, minWidth: "1px" }}>
                  <Typography variant="subtitle1" mb={2} fontWeight="medium">
                    Next Step (Optional)
                  </Typography>
                  <Select
                    fullWidth
                    value={option.nextStepId || ""}
                    onChange={(e) =>
                      changeOptionValue("nextStepId", e.target.value, index)
                    }
                  >
                    {questions.map((q, qIndex) => {
                      return q.id === question.id ? null : (
                        <MenuItem value={q.id} key={q.id}>
                          {q.variableName || "Question " + (qIndex + 1)}
                        </MenuItem>
                      );
                    })}
                    <MenuItem value={"exit"} key={"exit"}>
                      Exit Bot
                    </MenuItem>
                  </Select>
                </Box>
              )}
              <Box sx={{ flexShrink: 0, alignSelf: "flex-end" }}>
                <IconButton onClick={addNewOption}>
                  <Add />
                </IconButton>
                <IconButton
                  onClick={() => deleteOption(index)}
                  disabled={choices.length === 1}
                  color="error"
                >
                  <Delete />
                </IconButton>
              </Box>
            </Box>
            <Box sx={{ mt: 2 }}>
              <Typography variant="subtitle1" mb={2} fontWeight="medium">
                Response (Optional)
              </Typography>
              <ReactQuill
                modules={quillModules}
                formats={quillFormats}
                placeholder="Response"
                defaultValue={option.response}
                className="quill-container"
                onChange={(data: any) =>
                  changeOptionValue("response", data, index)
                }
              />
            </Box>
            <Box sx={{ mt: 2 }}>
              <Typography variant="subtitle1" mb={2} fontWeight="medium">
                Response in Chinese (Optional)
              </Typography>
              <ReactQuill
                modules={quillModules}
                formats={quillFormats}
                placeholder="Response"
                defaultValue={option.responseZh}
                className="quill-container"
                onChange={(data: any) =>
                  changeOptionValue("responseZh", data, index)
                }
              />
            </Box>
            <Box sx={{ mt: 2 }}>
              <Typography variant="subtitle1" mb={2} fontWeight="medium">
                Response in Malay (Optional)
              </Typography>
              <ReactQuill
                modules={quillModules}
                formats={quillFormats}
                placeholder="Response"
                defaultValue={option.responseMs}
                className="quill-container"
                onChange={(data: any) =>
                  changeOptionValue("responseMs", data, index)
                }
              />
            </Box>
            <Box sx={{ mt: 2 }}>
              <Typography variant="subtitle1" mb={2} fontWeight="medium">
                Response in Tamil (Optional)
              </Typography>
              <ReactQuill
                modules={quillModules}
                formats={quillFormats}
                placeholder="Response"
                defaultValue={option.responseTa}
                className="quill-container"
                onChange={(data: any) =>
                  changeOptionValue("responseTa", data, index)
                }
              />
            </Box>
          </Box>
        );
      })}
    </>
  );
};

export default QuestionOptions;
