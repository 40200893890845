import { Box, Typography } from "@mui/material";
import {
  GrayBox,
  HeaderStyle,
  PolicyBorderedContainer,
  PolicyContainer,
} from "../settings.style";
import { NavLink } from "react-router-dom";

const GoogleFit = () => {
  return (
    <>
      <Box sx={HeaderStyle}>
        <img src="/header-logo.svg" alt="logo" />
      </Box>
      <Box sx={GrayBox} />
      <Box
        sx={{
          height: "calc(100vh - 143px)",
          overflow: "auto",
          bgcolor: "#fff",
        }}
      >
        <Box sx={PolicyContainer}>
          <Box sx={PolicyBorderedContainer}>
            <Typography variant="h2">
              How we use and store your data?
            </Typography>
            <Box sx={{ mb: 2 }}>
              <Typography variant="h5">Access</Typography>
              <ul className="google-fit-ul">
                <li>
                  Mahalo Inc. app will not collect the data on steps and sleep
                  without your permission to connect the app to Google Fit. Well
                  Feet pulls the data on steps and sleep from Google Fit, it
                  does not override any data in Google Fit.
                </li>
              </ul>
            </Box>
            <Box sx={{ mb: 2 }}>
              <Typography variant="h5">Use </Typography>
              <ul className="google-fit-ul">
                <li>
                  We use your data to provide the services to you and to make
                  sure the <b>app serves its purpose</b> by providing steps and
                  sleep tracker, feet and food diary, education and medication
                  reminders. These features will assist you in diabetes and foot
                  health self-management.
                </li>
                <li>
                  We use your data to <b>personalize your experience</b> by
                  recommending learning materials, or helping you track your
                  activity and goals, including on steps and sleep. This will
                  help you improve your diabetes self-management.
                </li>
                <li>
                  We use your data to <b>manage, improve or modify the app</b>
                </li>
                <li>
                  We use your data to contact you{" "}
                  <b>for feedback and surveys</b>
                </li>
                <li>
                  We use your de-identified data to{" "}
                  <b>conduct a research study</b> on the correlation between
                  health literacy, app engagement and health outcomes
                </li>
                <li>
                  We might use your data to{" "}
                  <b>send you marketing/promotional material</b> and contest
                  giveaways by email or through social media applications in the
                  future; and
                </li>
              </ul>
            </Box>
            <Box sx={{ mb: 2 }}>
              <Typography variant="h5">Store </Typography>
              <ul className="google-fit-ul">
                <li>
                  Your data is stored at secure server that belongs to Nanyang
                  Technological University, Singapore (NTU Cloud based on Google
                  Cloud). It is protected with multilayered encryption
                  algorithms.
                </li>
              </ul>
            </Box>
            <Box sx={{ mb: 2 }}>
              <Typography variant="h5">Share </Typography>
              <ul className="google-fit-ul">
                <li>We do not share your data with any third party.</li>
                <li>
                  Data used in research is anonymised before the analysis.
                </li>
              </ul>
            </Box>
            <Box sx={{ mb: 2 }}>
              <Typography variant="h5">Watch a video about: </Typography>
              <Box sx={{ my: 3, display: "flex", justifyContent: "center" }}>
                <iframe
                  width="560"
                  height="315"
                  title="Connecting to Google Fit"
                  style={{ border: "none", maxWidth: "100%" }}
                  src="https://www.youtube.com/embed/X5dRijDbnH8"
                ></iframe>
              </Box>
              <ul className="google-fit-ul">
                <li>connecting Well Feet to Google Fit,</li>
                <li>
                  how connecting steps help you track your activity and daily
                  goals
                </li>
                <li>
                  how the doctor can change your daily goals based on your
                  activity
                </li>
              </ul>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
              Refer to our privacy policy{" "}
              <NavLink to="/privacy-policy/en" style={{ marginLeft: "5px" }}>
                here
              </NavLink>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default GoogleFit;
